import logo from './logo.svg';
import './App.css';
import $ from 'jquery';

function App() {
  
  $(window).scroll(function () {
    if ($(this).scrollTop() > 0) {
      $('.navbar').addClass('nav-sticky');
    } else {
      $('.navbar').removeClass('nav-sticky');
    }
  });

  return (
    <div data-target=".navbar">
      <header>
        <div className="navbar navbar-expand-lg bg-light navbar-light">
          <div className="container-fluid">
            <a href="index.html" className="navbar-brand">
              <img src="/img/Official Appearances Tech Logo.png" alt="Company Logo" />
              <div className="brandDescrp">
                <p className="brandName">Appearances Tech</p>
                <p className="brandSlogan">Create Your Digital Appearances</p>
              </div>
            </a>
            <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
              <div className="navbar-nav ml-auto">
                <a href="#home" className="nav-item nav-link">Home</a>
                <a href="#about" className="nav-item nav-link">About</a>
                <a href="#service" className="nav-item nav-link">Service</a>
                <a href="#experience" className="nav-item nav-link">Timeline</a>
                <a href="#portfolio" className="nav-item nav-link">Portfolio</a>
                <a href="#price" className="nav-item nav-link">Price</a>
                <a href="#review" className="nav-item nav-link">Review</a>
                <a href="#blog" className="nav-item nav-link">Blog</a>
                <a href="#faq" className="nav-item nav-link">Help</a>
                <a href="#footer" className="nav-item nav-link">Contact</a>
              </div>
              <div>
                <a href="#booking" className="btn btn-outline-primary">Book free consultation <span>&#x21e8;</span></a>
              </div>
            </div>
          </div>
        </div>
      </header>
      <main>
        <div className="hero" id="home">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-sm-12 col-md-6">
                <div className="hero-content">
                  <div className="hero-text">
                    <p>One-Stop Solutions For</p>
                    <h1 className="h4">Your Business</h1>
                    <h2></h2>
                    <h1 className="typed-text">Web Development, App Development, Social Media Marketing, Brand Scaling, Lead Generation, Content Writing, Logo Designing, Graphics Designing, Ad Banner, Video  Editing, Front-End Development, Back-End Develop
                    </h1>
                  </div>
                  <div className="hero-btn">
                    <a className="btn" href="#booking">Contact Us</a>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 d-none d-md-block">
                <div className="hero-image">
                  <img src="img/hero.png" alt="Hero Image" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about mt-2" id="about">
          <div className="container-fluid">
            <div className="section-header text-center">
              <p>About Us</p>
              <h2>#1 Digital Solution With <span className="">4 Years</span> Of
                Experience</h2>
            </div>
            <div className="row align-items-center about_body">
              <div className="about_container col-lg-5">
                <div className="container-xl about-img">
                  <img className="img-fluid" src="img/about.jpg" alt="" />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="about-content">
                  <div className="section-header text-center">
                  </div>
                  <div className="about-text">
                    <p>
                      Our digital solutions at <span>Appearances Tech</span>
                      We are Appearances Tech, a team of experienced and talented IT professionals who are passionate about helping businesses grow. We offer a wide range of services, including Website design, Mobile app development, Social media marketing, Graphics, Logo design, Banner design, SEO, and more. We work closely with our clients to understand their needs and goals, and we develop customized solutions that help them achieve their objectives.
                    </p>
                  </div>
                  <div className="scrldiv">
                    <div className="row g-5 pt-2 mb-5">
                      <div className="col-sm-6 about-sub-content">
                        <div className="about_icon_head">
                          <img className="about-icon img-fluid mb-4" src="img/icon/icon-7.png" alt="" />
                          <h5 className="mb-4">Hi-Tech Solutions</h5>
                        </div>
                        <p>With the aid of advanced and professional software such as the Adobe Creative Suite, Autodesk Collections, and industry-leading development tools, We promise to keep up with the latest technology, making sure your projects not only meet today's standards but also stay ahead by anticipating future industry needs.</p>
                      </div>
                      <div className="col-sm-6 about-sub-content">
                        <div className="about_icon_head">
                          <img className="about-icon img-fluid mb-4" src="img/icon/icon-2.png" alt="" />
                          <h5 className="mb-4">Experienced Developers</h5>
                        </div>
                        <p>Our team has a combined experience of over 4 years in the IT industry. We have worked with a wide range of clients, from small businesses to large corporations. We have a proven track record of success, and we are confident that we can help you grow your business.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-xxl mb-5">
          <div className="whyus">
            <div className="section-header text-center">
              <p>Why Choose Us?</p>
              <h2>A <span className="">Client-centric</span> Approach</h2>
            </div>
            <div className="mt-5 d-flex justify-content-between easystepoutercontainer">
              <div className="esyStepsContainer feature-row">
                <div className="phoneResponse">
                  <div className="esyStepsBox">
                    <div className="esyStepInfo">
                      <div className="easyStepHead">
                        <div className="esyStepsImg">
                          <img src="img/icon/rating.png" />
                        </div>
                        <div className="easyStepNo">
                          <h3>01.</h3>
                        </div>
                        <div className="easyStepHeading">
                          <h5>Streamlined Solutions:</h5>
                        </div>
                      </div>
                      <p>Hassle-Free Experience with One-Stop Solutions. We offer a wide range of services. We analyze and implement customized solutions to fulfill your business requirements from designing your website to developing and even hosting. We also offer a variety of other services, such as search engine optimization (SEO) and social media marketing and more as per your requirements.</p>
                    </div>
                  </div>
                  <div className="esyStepsBox">
                    <div className="esyStepInfo">
                      <div className="easyStepHead">
                        <div className="esyStepsImg">
                          <img src="img/icon/pay icon.png" />
                        </div>
                        <div className="easyStepNo">
                          <h3>03.</h3>
                        </div>
                        <div className="easyStepHeading">
                          <h5>Flexible Pricing:</h5>
                        </div>
                      </div>
                      <p>We are affordable. We offer competitive prices on our services, and we are always willing to work with you to find a budget that fits your needs. Discover budget-friendly tech solutions for your Business. Our flexible pricing ensures High-quality services without breaking the bank. Embrace creativity without financial problems.</p>
                    </div>
                  </div>
                </div>
                <div className="phoneResponse">
                  <div className="esyStepsBox">
                    <div className="esyStepInfo">
                      <div className="easyStepHead">
                        <div className="esyStepsImg">
                          <img src="img/icon/services.png" />
                        </div>
                        <div className="easyStepNo">
                          <h3>02.</h3>
                        </div>
                        <div className="easyStepHeading">
                          <h5>Professional Experts:</h5>
                        </div>
                      </div>
                      <p>We have a team of experienced and qualified professionals. Our team has been working in the digital business industry for many years, and we have a proven track record of success. We are also up-to-date on the latest trends and technologies, so you can be sure the conducive growth of your business.</p>
                    </div>
                  </div>

                  <div className="esyStepsBox">
                    <div className="esyStepInfo">
                      <div className="easyStepHead">
                        <div className="esyStepsImg">
                          <img src="img/icon/SupportIcon.png" />
                        </div>
                        <div className="easyStepNo">
                          <h3>04.</h3>
                        </div>
                        <div className="easyStepHeading">
                          <h5>24/7 Support:</h5>
                        </div>
                      </div>
                      <p>Experience uninterrupted progress with our 24/7 support. Your tech challenges become
                        opportunities with our dedicated assistance, ensuring your success around the clock.
                        Trust in seamless solutions.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="service" id="service">
          <div className="container">
            <div className="section-header text-center">
              <p>What I do</p>
              <h2>Exceptional <span className="">Solutions</span> for Your Business</h2>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="service-item">
                  <div className="service-icon">
                    <i className="fa fa-laptop"></i>
                  </div>
                  <div className="service-text">
                    <h3>Digital Marketing</h3>
                    <p>
                      Work with data-driven tactics to propel expansion. With the help of our
                      knowledgeable digital marketing services, can increase brand awareness, engage
                      audiences, and turn leads into satisfied customers.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="service-item">
                  <div className="service-icon">
                    <i className="fa fa-laptop-code"></i>
                  </div>
                  <div className="service-text">
                    <h3>Web Design & Development</h3>
                    <p>
                      Professional web development services to increase your online presence, featuring
                      user-friendly interfaces, responsive functionality, and distinctive designs.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="service-item">
                  <div className="service-icon">
                    <i className="fab fa-android"></i>
                  </div>
                  <div className="service-text">
                    <h3>Mobile App Development</h3>
                    <p>
                      Personalized app solutions to improve your company. proficiency with web, Android,
                      and iOS apps. supplying clients with originality and usefulness.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="service-item">
                  <div className="service-icon">
                    <i className="fa fa-camera-retro"></i>
                  </div>
                  <div className="service-text">
                    <h3>Visual Design Excellence</h3>
                    <p>
                      I specialize in creating visually appealing and effective graphics for businesses of all sizes. I can design logos, banners, posters, social media posts, and more. My work is professional, creative, and affordable. I will work with you to create graphics that meet your specific needs and goals.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="experience" id="experience">
          <div className="container">
            <div className="section-header text-center">
              <p>Happy Clients</p>
              <h2>Client <span className="" >Journeys</span></h2>
            </div>
            <div className="timeline">
              <div className="timeline-item left">
                <div className="timeline-text">
                  <div className="timeline-date">08/2023 - 08/2023</div>
                  <h2>HomeSpan | Website Development </h2>
                  <h4>Ranchi, Jharkhand, India</h4>
                  <p>
                    created a website for HomeSpan that acts as a mirror of the outstanding interior design
                    attributes that the company represents. We effectively demonstrate HomeSpan's
                    capabilities to prospective customers by means of insightful reflections on the website,
                    offering an enthralling window into the realm of creative and sophisticated interior
                    design.
                  </p>
                </div>
              </div>
              <div className="timeline-item right">
                <div className="timeline-text">
                  <div className="timeline-date">02/2023 - 04/2023</div>
                  <h2>Successfulethics | Instagram Marketing</h2>
                  <h4>Des Moines, IA, USA</h4>
                  <p>
                    Using pertinent hashtags, carefully selecting interesting material, and encouraging real
                    conversations we help him build an authentic community and increase your Instagram
                    following over time.
                  </p>
                </div>
              </div>
              <div className="timeline-item left">
                <div className="timeline-text">
                  <div className="timeline-date">01/2023 - 02/2023</div>
                  <h2>Galaxy Fashion | Website Development</h2>
                  <h4>Patna, Bihar, India</h4>
                  <p>
                    For a clothes retail store, advanced web development is essential to attract customers
                    and showcase your distinctive style. We've designed a website that fulfills all the
                    functionalities to help Jamhai Raja Retail Shop achieve its goals.
                  </p>
                </div>
              </div>
              <div className="timeline-item right">
                <div className="timeline-text">
                  <div className="timeline-date">03/2022 - 04/2022</div>
                  <h2>Wood & General Industries Ltd. | Mobile App Development</h2>
                  <h4>Patna, Bihar, India</h4>
                  <p>
                    Crafted a mobile app with a versatile ecosystem for iOS, Android, and Windows laptops.
                    Enhancing customer experiences, it aids Wood & General in seamless stock management,
                    fostering sales growth both in-store and across digital markets.
                  </p>
                </div>
              </div>
              <div className="timeline-item left">
                <div className="timeline-text">
                  <div className="timeline-date">08/2021 - 08/2022</div>
                  <h2>Techno India University | Digital Marketing</h2>
                  <h4>Salt Lake, Kolkata, India</h4>
                  <p>
                    I actively contributed to the field of digital marketing throughout my time at Techno
                    India University, working with others on a variety of projects and campaigns to raise
                    awareness of the university's objectives and interact with the online community.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="banner">
          <div className="container">
            <div className="section-header text-center">
              <p>Reasonable Price</p>
              <h2>Get A <span>Special</span> Price</h2>
            </div>
            <div className="container banner-text">
              <p>
                At Appearances Tech, we believe in offering competitive prices for our top-notch services with
                free demo works. We understand the value of your investment, and our pricing reflects our
                commitment to affordability without compromising on quality. Let us help you achieve your goals
                with our cost-effective solutions.
              </p>
              <a className="btn" href="#price">Pricing Plan</a>
            </div>
          </div>
        </div>
        <div className="portfolio" id="portfolio">
          <div className="container">
            <div className="section-header text-center">
              <p>Our Work</p>
              <h2>Portfolio <span>Showcase</span></h2>
            </div>
            <div className="row portfolioSubContainer">
              <div className="col-lg-4 col-md-6 col-sm-12 portfolio-item filter-1">
                <div className="portfolio-wrap">
                  <div className="portfolio-img">
                    <img src="img/A.I tech website.jpg" alt="Image" />
                  </div>
                  <div className="portfolio-text">
                    <h3>A.I Tech Website</h3>
                    <a className="btn" href="img/portfolio-1.jpg" data-lightbox="portfolio">+</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 portfolio-item filter-2">
                <div className="portfolio-wrap">
                  <div className="portfolio-img">
                    <img src="img/portfolio-2.jpg" alt="Image" />
                  </div>
                  <div className="portfolio-text">
                    <h3>Wallet Mobile App</h3>
                    <a className="btn" href="img/walletapp.jpg" data-lightbox="portfolio">+</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 portfolio-item filter-3">
                <div className="portfolio-wrap">
                  <div className="portfolio-img">
                    <img src="img/motivationl page.jpg" alt="Image" />
                  </div>
                  <div className="portfolio-text">
                    <h3>Motivation IG page</h3>
                    <a className="btn" href="img/portfolio-3.jpg" data-lightbox="portfolio">+</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 portfolio-item filter-1">
                <div className="portfolio-wrap">
                  <div className="portfolio-img">
                    <img src="img/archtecht website.jpg" alt="Image" />
                  </div>
                  <div className="portfolio-text">
                    <h3>Architecture Website</h3>
                    <a className="btn" href="img/portfolio-4.jpg" data-lightbox="portfolio">+</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 portfolio-item filter-2">
                <div className="portfolio-wrap">
                  <div className="portfolio-img">
                    <img src="img/fitnessapp.png" alt="Image" />
                  </div>
                  <div className="portfolio-text">
                    <h3>Fitness App</h3>
                    <a className="btn" href="img/portfolio-5.png" data-lightbox="portfolio">+</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 portfolio-item filter-3">
                <div className="portfolio-wrap">
                  <div className="portfolio-img">
                    <img src="img/portlogobanner.gif" alt="Image" />
                  </div>
                  <div className="portfolio-text">
                    <h3>Brand Logo Design</h3>
                    <a className="btn" href="img/portlogo.svg" data-lightbox="portfolio">+</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="banner">
          <div className="container">
            <div className="section-header text-center">
              <p>Great Deal</p>
              <h2>Get <span>30%</span> Discount</h2>
            </div>
            <div className="container banner-text">
              <p>
                Greetings from Appearances Tech, where quality and innovation merge. With your enrollment in our
                cutting-edge 1st IT services, we are happy to offer to you an exclusive 30% discount. We are
                your reliable digital partner because of commitment to your success, we stand as your trusted
                digital partner.
              </p>
              <a className="btn" href="#booking">Order Now</a>
            </div>
          </div>
        </div>
        <div className="price" id="price">
          <div className="container">
            <div className="section-header text-center">
              <p>Pricing Plan</p>
              <h2>Select the Right <span >Plan</span> for You</h2>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="price-item">
                  <div className="price-header">
                    <div className="price-title">
                      <h2>Basic</h2>
                    </div>
                    <div className="price-prices">
                      <h2><small>₹</small>5,000<span>+</span></h2>
                    </div>
                  </div>
                  <div className="price-body">
                    <div className="price-description">
                      <ul>
                        <li>Responsive Website</li>
                        <li>Basic SEO Optimization</li>
                        <li>24/7 Email Support</li>
                        <li>One Free Revision</li>
                        <li>Easy To Use</li>
                      </ul>
                    </div>
                  </div>
                  <div className="price-footer">
                    <div className="price-action">
                      <a className="btn" href="#booking">Order Now</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="price-item featured-item">
                  <div className="price-header">
                    <div className="price-title">
                      <h2>Standard</h2>
                    </div>
                    <div className="price-prices">
                      <h2><small>₹</small>15,000<span
                      >+</span></h2>
                    </div>
                  </div>
                  <div className="price-body">
                    <div className="price-description">
                      <ul>
                        <li>Responsive Website</li>
                        <li>Advanced SEO Optimization</li>
                        <li>24/7 Email and Phone Support</li>
                        <li>Two Free Revisions</li>
                        <li>Easy To Use</li>
                      </ul>
                    </div>
                  </div>
                  <div className="price-footer">
                    <div className="price-action">
                      <a className="btn" href="#booking">Order Now</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="price-item">
                  <div className="price-header">
                    <div className="price-title">
                      <h2>Premium</h2>
                    </div>
                    <div className="price-prices">
                      <h2><small>₹</small>30,000<span>+</span></h2>
                    </div>
                  </div>
                  <div className="price-body">
                    <div className="price-description">
                      <ul>
                        <li>Responsive Website with E-commerce</li>
                        <li>Full SEO Optimization</li>
                        <li>24/7 Email, Phone, and Live Chat Support</li>
                        <li>Unlimited Revisions</li>
                        <li>Social Media Marketing</li>
                      </ul>
                    </div>
                  </div>
                  <div className="price-footer">
                    <div className="price-action">
                      <a className="btn" href="#booking">Order Now</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-header text-center" id="booking">
          <p>Contact Us</p>
          <h2>Book Free <span>Consultation</span></h2>
        </div>
        <div className="contact">
          <div className="container-fluid">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6"></div>
                <div className="col-md-6">
                  <div className="contact-form">
                    <div id="success"></div>
                    <form name="sentMessage" id="contactForm" method="post">
                      <div className="control-group">
                        <input type="text" className="form-control inputText" id="name" name="name"
                          placeholder="Your Name" required="required"
                          data-validation-required-message="Please enter your name" />
                        <p className="help-block"></p>
                      </div>
                      <div className="control-group">
                        <input type="number" className="form-control inputText" id="phone" name="phone"
                          placeholder="Contact Number" required="required" pattern="[0-9]{10}"
                          data-validation-required-message="Please enter your phone number" />
                        <p className="help-block"></p>
                      </div>
                      <div className="control-group">
                        <input type="email" className="form-control inputText" id="email" name="email"
                          placeholder="Your Email" required="required"
                          data-validation-required-message="Please enter your email" />
                        <p className="help-block"></p>
                      </div>
                      <div className="control-group">
                        <select className="form-control select" id="subject" name="subject"
                          required="required"
                          data-validation-required-message="Please select a service">
                          <option value="basic" disabled selected>Select Service:</option>
                          <option value="basic">Basic Plan</option>
                          <option value="standard">Standard Plan</option>
                          <option value="premium">Premium Plan</option>
                          <option value="app">Custom Mobile App Development</option>
                          <option value="web">Custom Website Development</option>
                          <option value="socialmedia">Social Media Marketing</option>
                          <option value="seo">Search Engine Optimization (SEO)</option>
                          <option value="graph">Professional Graphics Design</option>
                          <option value="allservices">Full Digital Presence and Appearances Enhancement</option>
                          <option value="others">Others(Mention in Message)</option>
                        </select>
                        <p className="help-block"></p>
                      </div>
                      <div className="control-group">
                        <textarea className="form-control" id="message" name="message" placeholder="Message"
                          required="required"
                          data-validation-required-message="Please enter your message"></textarea>
                        <p className="help-block"></p>
                      </div>
                      <div>
                        <button className="btn" type="submit" id="sendMessageButton">Send Message</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-header text-center">
          <p>Testimonial</p>
          <h2>Our Client <span>Reviews</span></h2>
        </div>
        <div className="testimonial" id="review">
          <div className="container">
            <div className="testimonial-icon">
              <i className="fa fa-quote-left"></i>
            </div>
            <div className="owl-carousel testimonials-carousel">
              <div className="testimonial-item">
                <div className="testimonial-img">
                  <img src="img/testimonial-1.png" alt="Image" />
                </div>
                <div className="testimonial-text">
                  <p><h3>Excellent Web Development
                    Services</h3>⭐⭐⭐⭐⭐ <br />
                    Friends...I was Looking For Website Devloper...But Have Fear Inside About Scams...Suddenly I Found Appearances Tech...And They Made Me Clear about everything & Also Satisfied With Their Works...So I Recommend All My Friends....To Part Of This Group For
                  </p>
                  <h3>Rishav Singh</h3>
                  <h4>Builder</h4>
                </div>
              </div>
              <div className="testimonial-item">
                <div className="testimonial-img">
                  <img src="img/testimonial-3.png" alt="Image" />
                </div>
                <div className="testimonial-text">
                  <p><h3>Responsive and Innovative Web
                    Developers</h3>⭐⭐⭐⭐⭐ <br />
                    Appearances Tech. demonstrated responsiveness and creativity in creating a visually
                    attractive website that brought our vision to life. Although there were some minor
                    delays, the overall result was definitely worth the wait. We appreciate their
                    professionalism as well as the creative staff that is working on our project.</p>
                  <h3>Danish Ahmed</h3>
                  <h4>Interior Designer</h4>
                </div>
              </div>
              <div className="testimonial-item">
                <div className="testimonial-img">
                  <img src="img/testimonial-2.png" alt="Image" />
                </div>
                <div className="testimonial-text">
                  <p><h3>Professionalism and
                    Reliability</h3>⭐⭐⭐⭐⭐ <br />
                    Appearances Tech. showed proficiency and dependability in delivering a good revenue
                    website. While the project went smoothly, post-launch support might be improved.
                    Nonetheless, we are pleased with the overall quality of their job.
                  </p>
                  <h3>Vijay Kumar</h3>
                  <h4>Interior Designer</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="blog" id="blog">
          <div className="container">
            <div className="section-header text-center">
              <p>Our Blog</p>
              <h2>Latest <span>Articles</span></h2>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="blog-item">
                  <div className="blog-img">
                    <img src="img/blog-1.jpg" alt="Blog" />
                  </div>
                  <div className="blog-text" >
                    <h2>Why a Mobile-Responsive Website is Vital for Business Growth</h2>
                    <div className="blog-meta">
                      <p><i className="far fa-user"></i>Admin</p>
                      <p><i className="far fa-list-alt"></i>Web Design</p>
                      <p><i className="far fa-calendar-alt"></i>22-Jan-2023</p>
                    </div>
                    <p>
                      A adaptable site design is essential for organizations in this digital age. It
                      guarantees that your website runs smoothly across all platforms and provides the
                      best possible user experience. This increases traffic to your website while also
                      keeping users interested and raising your SEO results. Because of the increase in
                      mobile users, responsive design successfully reaches this group of people.
                      Maintaining a single website as opposed to distinct desktop and mobile versions is
                      more cost-effective. Additionally, it improves brand consistency, increases
                      conversion rates, and fortifies your online presence for the future.
                      Adopt responsive design to maintain your competitive edge and create the conditions
                      for success online.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="blog-item">
                  <div className="blog-img">
                    <img src="img/blog-2.jpg" alt="Blog" />
                  </div>
                  <div className="blog-text">
                    <h2>The Business Advantage of Mobile App Development</h2>
                    <div className="blog-meta">
                      <p><i className="far fa-user"></i>Admin</p>
                      <p><i className="far fa-list-alt"></i>Apps Design</p>
                      <p><i className="far fa-calendar-alt"></i>18-Oct-2023</p>
                    </div>
                    <p>
                      Mobile app development gives companies a competitive edge by improving client
                      engagement through real-time notifications and round-the-clock availability. Apps,
                      which are optimized for touch interactions, offer a better user experience and
                      increase user loyalty. Those
                      create profitable revenue streams and insightful data while growing with your
                      company.
                      expansion. Having a mobile app draws users and displays creativity. In an
                      In summary, developing mobile apps is crucial to satisfying modern client
                      meeting demands, offering ease, and maintaining an advantage in the online market.
                      Speak with our professionals to learn more and grow your company.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="faq_area section_padding_130" id="faq">
          <div className="container">
            <div className="section-header text-center">
              <p >FAQs</p>
              <h2>Most Asked <span >Questions</span> Let Us Help You</h2>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-sm-10 col-lg-12">
                <div className="accordion faq-accordian" id="faqAccordion">
                  <div className="card border-0">
                    <div className="card-header" id="headingOne">
                      <h6 className="mb-0 collapsed" data-toggle="collapse" data-target="#collapseOne"
                        aria-expanded="true" aria-controls="collapseOne">1. How can I increase my
                        internet visibility and business growth with Appearances Tech??<span
                          className="lni-chevron-up"><img
                            src="/img/icon/down-arrow.png" height="11px" width="21px" /></span></h6>
                    </div>
                    <div className="collapse" id="collapseOne" aria-labelledby="headingOne"
                      data-parent="#faqAccordion">
                      <div className="card-body">
                        <p>Indeed! By strategically combining website development, app production,
                          graphic design, and social media marketing, Appearances Tech can help you
                          increase your online presence and propel business growth. Here is a
                          tutorial on how we do this:
                          <ul>
                            <li><strong>Website Development</strong></li>
                            <li><strong>App Creation</strong></li>
                            <li><strong>Graphic Design</strong></li>
                            <li><strong>Social Media Marketing</strong></li>
                            <li><strong>Search Engine Optimization (SEO)</strong></li>
                            <li><strong>Analytics and Monitoring</strong></li>
                            <li><strong>Customer Engagement</strong></li>
                            <li><strong>Collaborate and Network</strong></li>
                            <li><strong>Continuous Improvement</strong></li>
                          </ul>
                          With the help of Appearances Tech's services, you can put these methods into
                          practice to build a strong online presence, successfully engage your audience,
                          and accelerate business growth. Recall that the secret is to maintain
                          consistency, track outcomes, and modify your strategy in response to information
                          and criticism.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card border-0">
                    <div className="card-header" id="headingTwo">
                      <h6 className="mb-0 collapsed" data-toggle="collapse" data-target="#collapseTwo"
                        aria-expanded="true" aria-controls="collapseTwo">2. How do I contact you
                        specifically for website development services?<span className="lni-chevron-up"><img
                          src="/img/icon/down-arrow.png" height="11px"
                          width="21px" /></span></h6>
                    </div>
                    <div className="collapse" id="collapseTwo" aria-labelledby="headingTwo"
                      data-parent="#faqAccordion">
                      <div className="card-body">
                        <p>Getting in touch with us is easy! You have multiple options:
                          <ul>
                            <li><strong>Submit Form:</strong></li>
                            Fill out the contact form on our website, providing some initial details
                            about your project and requirements.
                            <li><strong>Call Us:</strong> Feel free to give us a call at
                              <strong>+91 6289-537-832</strong> to discuss your project directly with our team.</li>
                            <li><strong>Email Us:</strong>
                              Send us an email at <a href='mailto:support@appearancestech.com'>
                                <strong>support@appearancestech.com</strong>
                              </a> with your project details, and
                              we'll get back to you promptly.
                            </li>
                          </ul>
                          Once you've reached out, we'll schedule a free consultation for you. During this
                          consultation:
                          <ul>
                            <li><strong>Zoom Meeting:</strong></li>
                            We'll set up a Zoom meeting to discuss your project in detail.
                            <li><strong>Premium Website Design:</strong></li>
                            Our team will share insights on how we can make your website look premium
                            and stand out in the crowd.
                          </ul>
                          We look forward to the opportunity to collaborate with you on creating an
                          exceptional website for your needs</p>
                      </div>
                    </div>
                  </div>
                  <div className="card border-0">
                    <div className="card-header" id="headingThree">
                      <h6 className="mb-0 collapsed" data-toggle="collapse" data-target="#collapseThree"
                        aria-expanded="true" aria-controls="collapseThree">3. In what specific categories of websites do you specialize in developing?<span className="lni-chevron-up"><img
                          src="/img/icon/down-arrow.png" height="11px"
                          width="21px" /></span></h6>
                    </div>
                    <div className="collapse" id="collapseThree" aria-labelledby="headingThree"
                      data-parent="#faqAccordion">
                      <div className="card-body">
                        <p> We have experience creating a wide range of websites that are adapted to specific needs. Business websites, e-commerce platforms, portfolio websites, blogs, and custom web apps are among our specialties. Each project is thoroughly designed and developed to meet your specific needs and objectives.</p>
                      </div>
                    </div>
                  </div>
                  <div className="card border-0">
                    <div className="card-header" id="headingFour">
                      <h6 className="mb-0 collapsed" data-toggle="collapse" data-target="#collapseFour"
                        aria-expanded="true" aria-controls="collapseTwo">4. How long does it take to
                        develop a custom website?<span className="lni-chevron-up"><img
                          src="/img/icon/down-arrow.png" height="11px"
                          width="21px" /></span></h6>
                    </div>
                    <div className="collapse" id="collapseFour" aria-labelledby="headingFour"
                      data-parent="#faqAccordion">
                      <div className="card-body">
                        <p>Custom website creation times can vary depending on the project's complexity and individual requirements. The process often takes several weeks to several months. It would be beneficial to discuss the complexities of your project, such as features, design components, and functionality, in order to provide a more realistic quote. Book a free digital consultation on <strong>Zoom App</strong> to better understand your requirements and deliver a more exact timescale for your bespoke website development.</p>
                      </div>
                    </div>
                  </div>
                  <div className="card border-0">
                    <div className="card-header" id="headingFive">
                      <h6 className="mb-0 collapsed" data-toggle="collapse" data-target="#collapseFive"
                        aria-expanded="true" aria-controls="collapseFive">5. What platforms do you
                        develop apps for (iOS, Android, both)?<span className="lni-chevron-up"><img
                          src="/img/icon/down-arrow.png" height="11px"
                          width="21px" /></span></h6>
                    </div>
                    <div className="collapse" id="collapseFive" aria-labelledby="headingFive"
                      data-parent="#faqAccordion">
                      <div className="card-body">
                        <p>We specialize in creating apps for both the <strong>iOS</strong> and <strong>Android</strong> platforms. Our team has the competence to build high-quality mobile applications adapted to your needs, whether you want to target a single operating system or design a cross-platform solution.</p>
                      </div>
                    </div>
                  </div>
                  <div className="card border-0">
                    <div className="card-header" id="headingSix">
                      <h6 className="mb-0 collapsed" data-toggle="collapse" data-target="#collapseSix"
                        aria-expanded="true" aria-controls="collapseSix">6. What is the duration of maintenance provided for the websites and apps developed by your team?<span className="lni-chevron-up"><img
                          src="/img/icon/down-arrow.png" height="11px"
                          width="21px" /></span></h6>
                    </div>
                    <div className="collapse" id="collapseSix" aria-labelledby="headingFour"
                      data-parent="#faqAccordion">
                      <div className="card-body">
                        <p>Yes, we offer comprehensive post-launch support and regular updates to ensure
                          your app remains secure, compatible with new devices and operating systems,
                          and equipped with the latest features. Our commitment extends for as long as you need assistance, ensuring a reliable and smooth digital experience for your platform.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="support-button text-center d-flex align-items-center justify-content-center mt-4"

                >
                  <img src="/img/icon/idea.png" width="45px" />
                  <p className="mb-0 px-2">Can't find your answers?</p>
                  <a href="#booking" > Contact us!</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer>
        <div className="footer" id="footer">
          <div className="container-fluid">
            <div className="container">
              <div className="footer-info">
                <div className="footer-menu">
                  <p className="contactdetails">(+91) 8252-935-614 / (+91) 6201-056-387</p>
                  <a href='mailto:support@appearancestech.com'>
                    <p className="contactdetails">support@appearancestech.com</p>
                  </a>
                </div>
                <img src="/img/AppearancesTech Banner.png" />
                <h3>Street-285, CD-Block, Action Area-I, New Town, Kolkata, India</h3>
              </div>
            </div>
            <div className="container copyright">
              <p>&copy; <a href="#">Appearances Tech</a>, All Right Reserved | Designed By <a href="#">Apperances
                Tech</a></p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
